<template>
  <div class="container">

    <div class="left-board">
      <div class="mobile-main">
        <div class="mobile-preview-main">
          <template v-if="data && data.length > 0">
            <div v-for="item in data" class="mobile-item" :key="item">
              <label class="title"><span style="color: #f00000; margin-right: 5px;" v-if="item.required">*</span>{{item.label}}</label>
              <!--1：单行文本-->
              <el-input v-if="item.type === '1'" type="text" :placeholder="item.placeholder"></el-input>
              <!--2：多行文本-->
              <el-input v-if="item.type === '2'" type="textarea" :rows="3" :placeholder="item.placeholder"></el-input>
              <!--3：数字-->
              <el-input-number v-if="item.type === '3'" :placeholder="item.placeholder" style="width: 100%;"></el-input-number>
              <!--4：日期-->
              <el-date-picker v-if="item.type === '4'" type="date" :placeholder="item.placeholder" style="width: 100%;"></el-date-picker>
              <!--5：时间-->
              <el-time-picker v-if="item.type === '5'" :placeholder="item.placeholder"></el-time-picker>
              <!--6：单项选择-->
              <el-radio-group v-if="item.type === '6'" style="width: 100%;">
                <el-radio :label="radio" v-for="radio in item.content.split(',')" :key="radio">{{radio}}</el-radio>
              </el-radio-group>
              <!--7：多项选择-->
              <template v-if="item.type === '7' && item.content && item.content.length > 0">
                <el-checkbox-group v-model="selectCheckbox" @change="handleCheckedCitiesChange">
                  <el-checkbox :label="checkbox" v-for="checkbox in item.content.split(',')" :key="checkbox">{{checkbox}}</el-checkbox>
                </el-checkbox-group>
              </template>
              <!--8：下拉框-->
              <el-select v-if="item.type === '8'" :placeholder="item.placeholder" v-model="temp[item.sort]">
                <el-option v-for="option in item.content.split(',')" :label="option" :value="option" :key="option">
                </el-option>
              </el-select>
              <!--9：上传图片-->
              <el-upload v-if="item.type === '9'" action="#" list-type="picture">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div>
              </el-upload>
              <!--10：上传附件-->
              <el-upload v-if="item.type === '10'" action="#">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="center-board">
      <div class="action-bar" @click="onClearClick">清除</div>
      <div class="center-scrollbar">
        <el-table :data="tableData" border @row-click="handleRowClick">
          <el-table-column label="字段名称">
            <template slot-scope="scope">
              <el-input placeholder="请输入字段名称" v-show="scope.row.edit" v-model="scope.row.label" @blur="labelBlur(scope)"></el-input>
              <span v-show="!scope.row.edit" @click="labelClick(scope)">{{scope.row.label === ''?'请点击设置字段名称':scope.row.label}}</span>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template slot-scope="scope">
              <el-select v-model="scope.row.type" clearable placeholder="请选择字段">
                <el-option v-for="item in filedType" :label="item.label" :value="item.type" :key="item">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="必填">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.required" active-color="#13ce66" inactive-color="#ff4949">
              </el-switch>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:20px;">
          <el-button @click="addFiled">添加</el-button>
          <el-button @click="release">预览</el-button>
<!--          <el-button @click="showTemp">查看</el-button>-->
        </div>
      </div>
    </div>

    <div class="right-board">
      <h1 class="board-title">组件属性</h1>
      <div v-if="selectFiled" class="field-box">
        <el-form :model="selectFiled" label-position="top">
          <!--字段类型-->
          <el-form-item label="字段类型">
            <el-select v-model="selectFiled.type" clearable placeholder="请选择字段" style="width: 100%;">
              <el-option v-for="item in filedType" :label="item.label" :value="item.type" :key="item"></el-option>
            </el-select>
          </el-form-item>
          <!--字段名称-->
          <el-form-item label="标题">
            <el-input placeholder="请输入标题" v-model="selectFiled.label"></el-input>
          </el-form-item>
          <!--占位提示-->
          <el-form-item label="提示信息">
            <el-input placeholder="请输入提示信息" v-model="selectFiled.placeholder"></el-input>
          </el-form-item>
          <!--开关-->
          <el-form-item label="必填">
            <el-switch v-model="selectFiled.required" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
          </el-form-item>
          <!--内容，根据各个属性不同，展示不同-->
          <el-form-item label="内容" v-if="selectFiled.type === '6' || selectFiled.type === '7' || selectFiled.type === '8'">
            <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="selectFiled.content"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import {list} from "@/api/system/filed";
import {getSessionStorageObj, setLocalStorageObj} from "@/utils/db";


export default {
  name: "Form",
  components: {

  },
  data(){
    return {
      //解决报错，临时对象
      temp:{},
      //表格中字段
      tableData: [],
      //系统字段类型
      filedType:[],
      //选中的数据
      selectFiled: null,
      //最后需要给后端的数据
      data: [],
      selectCheckbox: {},
    }
  },
  watch: {
    data: {
      handler(curVal,oldVal){
        this.autoSave()
      },
      deep:true
    }
  },
  mounted() {},
  created() {
    this.initFiled()
  },
  methods: {
    showTemp(){},
    //初始化所有系统字段
    initFiled(){
      list().then(r =>{
        this.filedType = r.data
      })
      for (let i = 0; i < 5; i++) {
        this.addFiled();
      }
    },
    //添加字段信息
    addFiled(){
      let filed = {
        content: null,
        label: '',
        placeholder: null,
        required: false,
        sort: this.tableData.length + 1,
        type: null,
        edit:false//用户判断是否编辑的字段
      }
      this.tableData.push(filed);
    },
    //字段名称点击
    labelClick(scope){
      scope.row.edit = true;
    },
    //字段名称失去焦点
    labelBlur(scope){
      scope.row.edit = false;
    },
    //监听行点击
    handleRowClick(row, column, event){
      this.selectFiled = row;
      this.selectFiled.placeholder = '请输入'
      this.release()
    },
    release(){
      //临时对象重置为空
      this.temp = {};
      //循环校验|循环判断
      let data = [];
      for (let i = 0; i < this.tableData.length; i++) {
        let temp = this.tableData[i];
        if(temp.type !== null && temp.type !== ''){
          let tempContent = null;
          if(temp.type === '6' || temp.type === '7' || temp.type === '8'){
            if(temp.content === null){
              this.$message.error('第'+(i+1)+'行没有选项值');
              return;
            }
            tempContent = temp.content
            // //回车符转为,
            // tempContent = temp.content.replace(/\n/g, ',');
            // //去除最后一个英文逗号
            // if(tempContent.substring(tempContent.length - 1) === ','){
            //   tempContent = tempContent.substring(0, tempContent.length - 1)
            // }
          }
          data.push({
            content: tempContent,
            fieldTypeId: temp.fieldTypeId,
            label: temp.label,
            placeholder: temp.placeholder,
            required: temp.required,
            sort: data.length + 1,
            type: temp.type,
          });
        }
      }
      this.data = data;
    },
    //实验多项选择
    handleCheckedCitiesChange() {
      console.log('this.selectCheckbox', this.selectCheckbox)
    },
    //清除
    onClearClick() {
      this.temp = {}
      this.tableData = []
      this.filedType = []
      this.selectFiled = null
      this.data = []
      this.selectCheckbox = {}
      this.initFiled()
    },
    autoSave() {
      let userId = 100
      let setStorageKey = 'data_' + userId //存储的key
      let setStorageValue = this.data ? this.data : [] //存储的value
      setLocalStorageObj(setStorageKey, setStorageValue)
    },
  }
}
</script>

<style lang="scss" scoped>
.container{
  width: 80%;
  background: #FFFFFF;
  min-height: calc(100vh - 60px);
  margin: 50px auto;
  border: 1px solid #cccccc;
  .left-board{
    width: 350px;
    padding: 15px;
    box-sizing: border-box;
    float: left;
    .mobile-main{
      width: 320px;
      margin: 20px auto 0;
      background: #FFFFFF;
      border-radius: 26px;
      overflow: hidden;
      -webkit-box-shadow: 0 18px 21px 0 rgba(0, 0, 0, 0.04);
      box-shadow: 0 18px 21px 0 rgba(0, 0, 0, 0.04);
      .mobile-preview-main{
        border: 1px solid #eeeeee;
        margin: 10px;
        padding: 10px;
        border-radius: 26px;
        overflow: auto;
        height: 667px;
        .mobile-item{
          width: 100%;
          .title{
            display: block;
            width: 100%;
            height: 40px;
            line-height: 40px;
            font-size: 16px;
            color: #606266;
          }
        }
      }
    }
    &-item {
      display: inline-block;
      padding: 8px 10px;
      background: #f6f7ff;
      font-size: 12px;
      cursor: move;
      border: 1px dashed #f6f7ff;
      border-radius: 3px;
      min-width: 48%;
      margin: 1%;
    }
  }
  .center-board{
    height: auto;
    width: calc(100% - 700px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    float: left;
    .action-bar{
      position: relative;
      height: 42px;
      line-height: 42px;
      text-align: right;
      padding: 0 15px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #f1e8e8;
    }
    .center-scrollbar{
      height: calc(100vh - 110px);
      overflow: hidden;
      border-left: 1px solid #f1e8e8;
      border-right: 1px solid #f1e8e8;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      padding: 15px;
    }
  }
  .right-board {
    float: right;
    width: 350px;
    .board-title{
      margin: 0px;
      width: 100%;
      text-align: center;
      height: 42px;
      font-size: 16px;
      line-height: 42px;
      border-bottom: 1px solid #f1e8e8;
    }
    .field-box{
      width: 100%;
      padding: 10px 15px;
      box-sizing: border-box;
    }
  }

}
</style>

