var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("div", { staticClass: "left-board" }, [
      _c("div", { staticClass: "mobile-main" }, [
        _c(
          "div",
          { staticClass: "mobile-preview-main" },
          [
            _vm.data && _vm.data.length > 0
              ? _vm._l(_vm.data, function (item) {
                  return _c(
                    "div",
                    { key: item, staticClass: "mobile-item" },
                    [
                      _c("label", { staticClass: "title" }, [
                        item.required
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "#f00000",
                                  "margin-right": "5px",
                                },
                              },
                              [_vm._v("*")]
                            )
                          : _vm._e(),
                        _vm._v(_vm._s(item.label)),
                      ]),
                      item.type === "1"
                        ? _c("el-input", {
                            attrs: {
                              type: "text",
                              placeholder: item.placeholder,
                            },
                          })
                        : _vm._e(),
                      item.type === "2"
                        ? _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              placeholder: item.placeholder,
                            },
                          })
                        : _vm._e(),
                      item.type === "3"
                        ? _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: item.placeholder },
                          })
                        : _vm._e(),
                      item.type === "4"
                        ? _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "date",
                              placeholder: item.placeholder,
                            },
                          })
                        : _vm._e(),
                      item.type === "5"
                        ? _c("el-time-picker", {
                            attrs: { placeholder: item.placeholder },
                          })
                        : _vm._e(),
                      item.type === "6"
                        ? _c(
                            "el-radio-group",
                            { staticStyle: { width: "100%" } },
                            _vm._l(item.content.split(","), function (radio) {
                              return _c(
                                "el-radio",
                                { key: radio, attrs: { label: radio } },
                                [_vm._v(_vm._s(radio))]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      item.type === "7" &&
                      item.content &&
                      item.content.length > 0
                        ? [
                            _c(
                              "el-checkbox-group",
                              {
                                on: { change: _vm.handleCheckedCitiesChange },
                                model: {
                                  value: _vm.selectCheckbox,
                                  callback: function ($$v) {
                                    _vm.selectCheckbox = $$v
                                  },
                                  expression: "selectCheckbox",
                                },
                              },
                              _vm._l(
                                item.content.split(","),
                                function (checkbox) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: checkbox,
                                      attrs: { label: checkbox },
                                    },
                                    [_vm._v(_vm._s(checkbox))]
                                  )
                                }
                              ),
                              1
                            ),
                          ]
                        : _vm._e(),
                      item.type === "8"
                        ? _c(
                            "el-select",
                            {
                              attrs: { placeholder: item.placeholder },
                              model: {
                                value: _vm.temp[item.sort],
                                callback: function ($$v) {
                                  _vm.$set(_vm.temp, item.sort, $$v)
                                },
                                expression: "temp[item.sort]",
                              },
                            },
                            _vm._l(item.content.split(","), function (option) {
                              return _c("el-option", {
                                key: option,
                                attrs: { label: option, value: option },
                              })
                            }),
                            1
                          )
                        : _vm._e(),
                      item.type === "9"
                        ? _c(
                            "el-upload",
                            { attrs: { action: "#", "list-type": "picture" } },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "el-upload__tip",
                                  attrs: { slot: "tip" },
                                  slot: "tip",
                                },
                                [_vm._v("只能上传jpg/png文件")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      item.type === "10"
                        ? _c(
                            "el-upload",
                            { attrs: { action: "#" } },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("点击上传")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                })
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    _c("div", { staticClass: "center-board" }, [
      _c(
        "div",
        { staticClass: "action-bar", on: { click: _vm.onClearClick } },
        [_vm._v("清除")]
      ),
      _c(
        "div",
        { staticClass: "center-scrollbar" },
        [
          _c(
            "el-table",
            {
              attrs: { data: _vm.tableData, border: "" },
              on: { "row-click": _vm.handleRowClick },
            },
            [
              _c("el-table-column", {
                attrs: { label: "字段名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: scope.row.edit,
                              expression: "scope.row.edit",
                            },
                          ],
                          attrs: { placeholder: "请输入字段名称" },
                          on: {
                            blur: function ($event) {
                              return _vm.labelBlur(scope)
                            },
                          },
                          model: {
                            value: scope.row.label,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "label", $$v)
                            },
                            expression: "scope.row.label",
                          },
                        }),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !scope.row.edit,
                                expression: "!scope.row.edit",
                              },
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.labelClick(scope)
                              },
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                scope.row.label === ""
                                  ? "请点击设置字段名称"
                                  : scope.row.label
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { clearable: "", placeholder: "请选择字段" },
                            model: {
                              value: scope.row.type,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "type", $$v)
                              },
                              expression: "scope.row.type",
                            },
                          },
                          _vm._l(_vm.filedType, function (item) {
                            return _c("el-option", {
                              key: item,
                              attrs: { label: item.label, value: item.type },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "必填" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          model: {
                            value: scope.row.required,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "required", $$v)
                            },
                            expression: "scope.row.required",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-button", { on: { click: _vm.addFiled } }, [
                _vm._v("添加"),
              ]),
              _c("el-button", { on: { click: _vm.release } }, [_vm._v("预览")]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "right-board" }, [
      _c("h1", { staticClass: "board-title" }, [_vm._v("组件属性")]),
      _vm.selectFiled
        ? _c(
            "div",
            { staticClass: "field-box" },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.selectFiled, "label-position": "top" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "字段类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { clearable: "", placeholder: "请选择字段" },
                          model: {
                            value: _vm.selectFiled.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.selectFiled, "type", $$v)
                            },
                            expression: "selectFiled.type",
                          },
                        },
                        _vm._l(_vm.filedType, function (item) {
                          return _c("el-option", {
                            key: item,
                            attrs: { label: item.label, value: item.type },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入标题" },
                        model: {
                          value: _vm.selectFiled.label,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectFiled, "label", $$v)
                          },
                          expression: "selectFiled.label",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提示信息" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入提示信息" },
                        model: {
                          value: _vm.selectFiled.placeholder,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectFiled, "placeholder", $$v)
                          },
                          expression: "selectFiled.placeholder",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "必填" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        model: {
                          value: _vm.selectFiled.required,
                          callback: function ($$v) {
                            _vm.$set(_vm.selectFiled, "required", $$v)
                          },
                          expression: "selectFiled.required",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.selectFiled.type === "6" ||
                  _vm.selectFiled.type === "7" ||
                  _vm.selectFiled.type === "8"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "内容" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              rows: 5,
                              placeholder: "请输入内容",
                            },
                            model: {
                              value: _vm.selectFiled.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectFiled, "content", $$v)
                              },
                              expression: "selectFiled.content",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }